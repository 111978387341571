/* eslint-disable no-param-reassign */
import React from 'react';
import DisplayLevel from '../../Types/BeecomingTypes/DisplayLevel';
import ApiFetch from '../RefreshToken/ApiRequest';

async function HandleCheckBoxActuality(
  recordid: string,
  sendNotif: boolean,
  highlight: string,
  setDisplay: (_l: DisplayLevel) => void,
  setHighlight: (_h: string) => void,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
  handleError: () => void,
  history: any,
  star: boolean,
  inCarousel: boolean,
  shown: boolean,
  currentBox: 'carousel' | 'shown' | 'star',
) {
  if (currentBox === 'carousel') {
    inCarousel = !inCarousel;
    if (inCarousel && !shown) {
      shown = true;
    }
  }

  if (currentBox === 'shown') {
    shown = !shown;
    if (!shown) {
      inCarousel = false;
      star = false;
    }
  }

  if (currentBox === 'star') {
    star = !star;
    if (!shown && star) {
      shown = true;
      inCarousel = true;
    }
  }

  const level = (() => {
    switch (true) {
      case shown && !inCarousel && !star:
        return DisplayLevel.Listed;
      case shown && inCarousel && !star:
        return DisplayLevel.Carousel;
      case shown && !inCarousel && star:
        return DisplayLevel.Highlight;
      case shown && inCarousel && star:
        return DisplayLevel.CarouselAndHighlight;
      case !shown:
      default:
        return DisplayLevel.Unlisted;
    }
  })();

  const requestData = {
    recordid,
    sendNotif,
    dataset: 'actualites_cclo',
    displayLevel: level,
  };

  setLoader(true);
  ApiFetch('/OpenData/record', 'PUT', history, requestData).then((response) => {
    if (response.ok) {
      setDisplay(level);
      if (!shown && recordid === highlight) {
        setHighlight('');
      }
      if (star && recordid !== highlight) {
        setHighlight(recordid);
      }
      if (!star && recordid === highlight) {
        setHighlight('');
      }
    } else {
      handleError();
    }
    setLoader(false);
    window.dispatchEvent(new Event('onReload'));
  });
}

export default HandleCheckBoxActuality;
